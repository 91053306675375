import { Component, OnInit, Output, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UserResponse } from '../models/user-response';
import { ModalService } from '../Services/modal.service';
import { EnterPasswordComponent } from '../enter-password/enter-password.component';
import { EmailComponent } from '../email/email.component';
import { SubInfoComponent } from '../sub-info/sub-info.component';
import { SharingService } from '../Services/sharing-service';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { Context } from '../models/context';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  registerForm: FormGroup;
  signInForm: FormGroup;
  checkSignInEmail = true;
  passMatch = true;
  pinumMatch = true;
  emptyInputs = false;
  showError = false;
  emailExists = true;
  isPassShown = false;
  isConfirmPassShown = false;
  appContext: Context;

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    private _sharedService: SharingService<Context>,
    private _service: RacemasterApiService,
    @Inject(DOCUMENT) document,
    private modalService: ModalService,
    private _router: Router) {
    this.registerForm = _fb.group({
      email: ["", Validators.required],
      username: ["", Validators.required],
      password: ["", [Validators.required, Validators.minLength(6)]],
      confirmpassword: ["", Validators.required],
      enterpinnum1: ["", Validators.required],
      enterpinnum2: ["", Validators.required],
      enterpinnum3: ["", Validators.required],
      enterpinnum4: ["", Validators.required],
      confirmpin1: ["", Validators.required],
      confirmpin2: ["", Validators.required],
      confirmpin3: ["", Validators.required],
      confirmpin4: ["", Validators.required],
    })
    this.signInForm = _fb.group({
      email: ["", null]
    })
  }
  ngOnInit() {
    this.appContext = this._sharedService.getMessage();
    this.signInForm.controls["email"].valueChanges.subscribe(x => this.showError = false);
  }
  onSignIn() {
    this.showError = true;
    this.emailExists = true;
    var email = this.signInForm.controls["email"].value;

    this._service.checkEmailExistance(email).subscribe((data: UserResponse) => {
    },
      (err) => {
        this.emailExists = false;
      },
      () => {
        if (!this.emailExists) {

          return;
        }
        this.appContext.LoginRequest.email = email;
        this._sharedService.setMessage(this.appContext);
        this._router.navigate(['enterpassword']);
      }
    );
  }


  errorHandler(error: any): void {
    this.emailExists = false;
  }
  onRegister() {
    this.showError = true;
    this.emailExists = true;

    var pinNumber = this.registerForm.controls["enterpinnum1"].value
      .concat(this.registerForm.controls["enterpinnum2"].value)
      .concat(this.registerForm.controls["enterpinnum3"].value)
      .concat(this.registerForm.controls["enterpinnum4"].value);

    var confirmPin = this.registerForm.controls["confirmpin1"].value
      .concat(this.registerForm.controls["confirmpin2"].value)
      .concat(this.registerForm.controls["confirmpin3"].value)
      .concat(this.registerForm.controls["confirmpin4"].value)

    var allInputs = this.registerForm.controls["username"].value
      .concat(this.registerForm.controls["email"].value)
      .concat(this.registerForm.controls["password"].value)
      .concat(this.registerForm.controls["confirmpassword"].value)
      .concat(pinNumber)
      .concat(confirmPin);
    var username = this.registerForm.controls["username"].value;
    var email = this.registerForm.controls["email"].value;
    var password = this.registerForm.controls["password"].value;
    var confirmpassword = this.registerForm.controls["confirmpassword"].value;

    if (allInputs.length === 0) {
      this.emptyInputs = true;
      return;
    }
    if (username.length == 0 || email.length == 0 || password.length == 0 || confirmpassword.length == 0 || pinNumber.length == 0 || confirmpassword.length == 0) {
      this.emptyInputs = true;
      return;
    }
    if (this.registerForm.controls["password"].value === this.registerForm.controls["confirmpassword"].value) {
      this.passMatch = true;

    }
    else {
      this.passMatch = false;
      return;
    }
    if (pinNumber !== confirmPin) {
      this.pinumMatch = false;
      return;
    }
    this.appContext.registerRequest.email = this.registerForm.controls['email'].value;
    this.appContext.registerRequest.userName = this.registerForm.controls['username'].value;
    this.appContext.registerRequest.password = this.registerForm.controls['password'].value;
    this.appContext.registerRequest.pinCode = pinNumber;
    // set message method sends info entered in this page to next page(subinfo)
    this._sharedService.setMessage(this.appContext);
    this.subInfo();
  }

  home() {
    this._router.navigate(['home']);
  }	
  Refresh() {
    window.location.reload();
  }
  subInfo() {
    this._router.navigate(['subinfo']);
  }
  togglePassword($event) {
    var imageTag = document.getElementById("togglePasswordImg");
    var inputTag1 = document.getElementById("password");
    this.isPassShown = !this.isPassShown;
    if (this.isPassShown) {
      // Case visible 
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Open.png");
      }
      if (inputTag1 != null) {
        inputTag1.setAttribute("type", "text");

      }
    }
    else {
      // Case Invisible
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Closed.png");
      }
      if (inputTag1 != null) {
        inputTag1.setAttribute("type", "password");
      }
    }
  }
  toggleConfirmPassword($event) {
    var imageTag2 = document.getElementById("toggleConfirmPasswordImg");
    var inputTag2 = document.getElementById("confirmpassword");
    this.isConfirmPassShown = !this.isConfirmPassShown;
    if (this.isConfirmPassShown)
    // case visible
    {
      if (imageTag2 != null) {
        imageTag2.setAttribute("src", "assets/Password Eye Open.png");
      }
      if (inputTag2 != null) {
        inputTag2.setAttribute("type", "text")
      }
    }
    else {
      // Case Invisible
      if (imageTag2 != null) {
        imageTag2.setAttribute("src", "assets/Password Eye Closed.png");
      }
      if (inputTag2 != null) {
        inputTag2.setAttribute("type", "password");
      }
    }
  }
  enterPasswordModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(EnterPasswordComponent, inputs, {});
  }
  enterPassword() {
    this._router.navigate(['enterpassword']);
  }
  public close() {
    this.modalService.destroy();
  }
  
  email() {
    this._router.navigate(['email']);
  }
  pinValidation() {
    // var pinNumber = this.registerForm.controls["enterpinnum1"].value;
    // if (!isNumeric(pinNumber)) {
    //   alert("not a number");
    // }
  }
}

