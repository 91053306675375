import { Component, OnInit } from '@angular/core';
import { Server } from '../../models/server'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RacemasterApiService } from 'src/app/Services/racemaster-api.service';
import { CollectionResponse } from 'src/app/models/collection-response';
import { StorageFile } from 'src/app/models/storage-file';
import { SharingService } from 'src/app/Services/sharing-service';
import { Context } from 'src/app/models/context';

@Component({
  selector: 'app-server-admin',
  templateUrl: './server-admin.component.html',
  styleUrls: ['./server-admin.component.css']
})
export class ServerAdminComponent implements OnInit {
  serverModel: Server;
  entryForm: FormGroup;
  server = new Server();
  storageFile = new StorageFile();
  appContext = new Context();

  constructor(fb: FormBuilder, private _racemasterService: RacemasterApiService, private _sharingService: SharingService<Context>) {
    this.entryForm = fb.group({
      portNumber: ["", Validators.required],
      serverAddress: ["", Validators.required],
      id: ["", Validators.required],
      name: ["", Validators.required],
      firstLine: ["", Validators.required],
      secondLine: ["", Validators.required],
      imageResource: ["", Validators.required],
      imgFile: ["", Validators.required]
    });

  }
  onFileSelected(event) {
    this.storageFile.name = event.target.files[0].name;
    this.storageFile.id = this.entryForm.controls["id"].value;
    this.storageFile.files[0] = event.target.files[0];

  }

  ngOnInit() {
    this.storageFile.bucketUrl =  this._sharingService.getMessage().cloudStorageUrl;
    this.storageFile.name = "Choose file...";
  }

  onSubmit() {
    //TODO: Fill the server instance from user input
    this.server.portNumber = this.entryForm.controls["portNumber"].value;
    this.server.serverAddress = this.entryForm.controls["serverAddress"].value;
    this.server.id = this.entryForm.controls["id"].value;
    this.server.name = this.entryForm.controls["name"].value;
    this.server.firstLineText = this.entryForm.controls["firstLine"].value;
    this.server.secondLineText = this.entryForm.controls["secondLine"].value;
    //TODO: Call the service postServer and pass  this.server as parameter
    this._racemasterService.post<Server>(this.server, "Servers");
    // form data represents a form of uploaded images, as it differs than json files it requires const formdata
    const formData: FormData = new FormData();
    formData.append("storagePath", "Racemaster.Files.Resources.Images");
    formData.append("id", this.storageFile.id);
    formData.append("bucketUrl", this.storageFile.bucketUrl);
    formData.append('file', this.storageFile.files[0], this.storageFile.name);

    let response : StorageFile;

    this._racemasterService.uploadFile<StorageFile>(formData).subscribe(
      (data: StorageFile) => { response = data; },
      (err) => {},
      () => { alert(response.dowloadUrl); }
    );

    //TODO: Create post method in APIservice and change url to "Servers" instead of "Channels"
  }
}
