import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { AnytimeComponent } from '../anytime/anytime.component';
import { SmartoddsSubsComponent } from '../smartodds-subs/smartodds-subs.component';
import { SmartoddsSelectSubsComponent } from '../smartodds-select-subs/smartodds-select-subs.component';
import { SmartoddsPremSubsComponent } from '../smartodds-prem-subs/smartodds-prem-subs.component';
import { RacemasterSelectComponent } from '../racemaster-select/racemaster-select.component';
import { RacemasterRatingsComponent } from '../racemaster-ratings/racemaster-ratings.component';
import { RacemasterPlusComponent } from '../racemaster-plus/racemaster-plus.component';
import { SmartAnytimeComponent } from '../smart-anytime/smart-anytime.component';
import { AnalysisComponent } from '../analysis/analysis.component';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { SmartoddsPlusComponent } from '../smartodds-plus/smartodds-plus.component';

@Component({
  selector: 'app-smartodds-suboptions',
  templateUrl: './smartodds-suboptions.component.html',
  styleUrls: ['./smartodds-suboptions.component.css']
})
export class SmartoddsSuboptionsComponent implements OnInit {
  appContext: Context;
  constructor(private modalService: ModalService, private _sharingService: SharingService<Context>) {
    this.appContext= this._sharingService.getMessage();
  }

  ngOnInit() {

  }
  goToSub(productId: string) {
    if (productId === "542B0611-E8C8-46E4-B092-DF7A321F1D3B") { this.smartSubModal(); }
    else if (productId === "C723BA71-A776-43C2-B6D9-5A9044476077") { this.ratingsModal(); }
    else if (productId === "A98AA626-0193-41DF-AAE9-FD2408A8074F") { this.selectModal(); }
    else if (productId === "4B89B322-CDBE-4952-8BF0-A9AADE079B8F") { this.racemasterPlusModal(); }
    else if (productId === "00CE0D37-472C-4C32-B85F-5841AD5674E7") { this.smartSelectModal(); }  
    else if (productId === "F8CC07A4-ECA3-43C4-A183-43CC2320C925") { this.smartPremModal(); }    
  

  }
  public close() {
    this.modalService.destroy();
  }
  ratingsModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(RacemasterRatingsComponent, inputs, {});
  }
  plusModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(RacemasterPlusComponent, inputs, {});
  }
  subOptionsModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(RacemasterSuboptionsComponent, inputs, {});
  }
  smartSubModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(SmartoddsSubsComponent, inputs, {});
  }

  smartSelectModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(SmartoddsSelectSubsComponent, inputs, {});
  }

  smartRatingsModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(SmartoddsPlusComponent, inputs, {});
  }
  smartPremModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(SmartoddsPremSubsComponent, inputs, {});
  }
  racemasterPlusModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(RacemasterPlusComponent, inputs, {});
  }

  selectModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(RacemasterSelectComponent, inputs, {});
  }

}
