import { Component, OnInit, Inject } from '@angular/core';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { Server } from '../models/server';
import { CollectionResponse } from '../models/collection-response';
import { SingleResponse } from '../models/single-response';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-all-servers',
  templateUrl: './all-servers.component.html',
  styleUrls: ['./all-servers.component.css']
})
export class AllServersComponent implements OnInit {
  allServers = new Array<Server>();
  serverModel: Server;
  constructor(private _service: RacemasterApiService, @Inject(DOCUMENT) document) { }

  ngOnInit() {
    this._service.getAll<Server>("Servers").subscribe(
      (data: CollectionResponse<Server>) => { this.allServers = data.collection; },
      (error) => { alert(error.message); }
    )
  }

  onSubmit() {
    this.serverModel = new Server();

    this.serverModel.id = document.getElementById("id").innerText;
    this.serverModel.portNumber = Number(document.getElementById("portNumber").innerText);
    this.serverModel.serverAddress = document.getElementById("serverAddress").innerText;
    this.serverModel.name = document.getElementById("name").innerText;
    this.serverModel.firstLineText = document.getElementById("firstLine").innerText;
    this.serverModel.secondLineText = document.getElementById("secondLine").innerText;

    this._service.put<Server>(this.serverModel, "Servers").subscribe(
      (data: SingleResponse) => { },
      (err) => { alert(err.message); },
      () => { alert("Server is successfuly updated!") }
    )
  }
  onDelete(serverModel: Server) {
    let result: string;
    this._service.delete<Server>(serverModel.id, "Servers").subscribe(
      (data: string) => { result = data },
      (error) => { alert(error.message) },
      () => {
        alert(result);
        this.refresh();
      }
    )
  }
  refresh() {
    window.location.reload();
  }
}
