import { Email } from './../models/email';
import { Injectable, KeyValueDiffers } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders } from '@angular/common/http';
import { CollectionResponse } from '../models/collection-response';
import { SingleResponse } from '../models/single-response';
import { LoginRequest } from '../models/login-request';
import { LoginResponse } from '../models/login-response';
import { UserResponse } from '../models/user-response';
import { map } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { AllServersComponent } from '../all-servers/all-servers.component';
import { MapOperator } from 'rxjs/internal/operators/map';
import { StorageFile } from '../models/storage-file';
import { WebsiteUpdates } from '../models/website-updates';
import { TypeofExpr } from '@angular/compiler';

export const apiBaseUrl = "https://9n6dnfgvmk.execute-api.us-west-2.amazonaws.com/Prod/api/";

@Injectable({
  providedIn: 'root'
})

export class RacemasterApiService {
  constructor(private _http: HttpClient) { }
  getAll<T>(requestUrl: string): Observable<CollectionResponse<T>> {
    let fullApiUrl = apiBaseUrl + requestUrl + "/getAll";

    return this._http.get<CollectionResponse<T>>(fullApiUrl);
  }
  feedback(postObj: Email, requestUrl): Observable<SingleResponse> {
    const options = { headers: { 'Content-Type': 'application/json' } };
    let fullApiUrl = apiBaseUrl + requestUrl ;


    return this._http.post<SingleResponse>(fullApiUrl, postObj, options);
  }
  post<T>(postObj: T, requestUrl): Observable<SingleResponse> {
    const options = { headers: { 'Content-Type': 'application/json' } };
    let fullApiUrl = apiBaseUrl + requestUrl + "/register";


    return this._http.post<SingleResponse>(fullApiUrl, postObj, options);
  }
  put<T>(postObj: T, requestUrl): Observable<SingleResponse> {
    const options = { headers: { 'Content-Type': 'application/json' } };
    let fullApiUrl = apiBaseUrl + requestUrl + "/update";

    return this._http.put<SingleResponse>(fullApiUrl, postObj, options);
  }
  findById<T>(id: string, requestUrl): Observable<T> {
    let params = new HttpParams().set('id', id);
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    let fullApiUrl = apiBaseUrl + requestUrl + "/findById";

    return this._http.get<T>(fullApiUrl, { params: params })
  }
  //create instance of dynamic texts in home component then call find by id in the ngonitt
  delete<T>(id: string, requestUrl: string): Observable<string> {
    let params = new HttpParams().set('id', id);
    let fullApiUrl = apiBaseUrl + requestUrl + "/delete";

    return this._http.delete(fullApiUrl, { params: params, responseType: 'text' });
  }
  getFileInfo(fileId: string): Observable<StorageFile> {
    let params = new HttpParams().set('id', fileId);
    let fullApiUrl = apiBaseUrl + "FileStorage/findById";

    return this._http.get<StorageFile>(fullApiUrl, { params: params });
  }
  uploadFile<T>(fileForm: FormData): Observable<T> {
    const options = { headers: { 'Content-Type': 'application/json' } };
    let fullApiUrl = apiBaseUrl + "FileStorage/register";

    return this._http.put<T>(fullApiUrl, fileForm, options);
  }


  readFile(downloadUrl: string) : Observable<any> {

    const headers = new HttpHeaders();
    return this._http.get(downloadUrl, { headers, responseType: 'blob' as 'json' })
  }

  createFileBlobUrl<T>(response):string{
    let binaryData = [];
    let dummy: T;

    binaryData.push(response);
    return window.URL.createObjectURL(new Blob(binaryData, { type: typeof(dummy) }));
  }

  downloadFile<T>(internalFileUrl): Observable<T>{
    return this._http.get<T>(internalFileUrl);
  }

  

  login(request: LoginRequest): Observable<LoginResponse> {
    const options = { headers: { 'Content-Type': 'application/json' } };

    return this._http.post<LoginResponse>(apiBaseUrl + "Users/login", request);
  }
  checkEmailExistance(email: string): Observable<UserResponse> {
    let params = new HttpParams().set('email', email);
    let fullApiUrl = apiBaseUrl + "Users/findByEmail";

    return this._http.get<UserResponse>(fullApiUrl, { params: params });
  }
}

class TemplatedClass<T> {
  constructor(value: T){
    this.value = value;
  }

  public value : T;
}

type GetMyClassT<C extends TemplatedClass<any>> = C extends TemplatedClass<infer T> ? T : unknown;