import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { ProductSubscription } from '../models/product-subscription';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smartodds-select-subs',
  templateUrl: './smartodds-select-subs.component.html',
  styleUrls: ['./smartodds-select-subs.component.css']
})
export class SmartoddsSelectSubsComponent implements OnInit {
  appContext: Context;
  threeMonthsSub: ProductSubscription;
  sixMonthsSub: ProductSubscription;
  twelveMonthsSub: ProductSubscription;
  isLoggedIn = false;
  constructor(private modalService: ModalService, private _sharingService: SharingService<Context>, private _router: Router) {
    this.appContext = this._sharingService.getMessage();
    this.isLoggedIn = this.appContext.activeUser != null;

  }

  ngOnInit() {
    this.threeMonthsSub = this.appContext.products.find(p => p.id === "00CE0D37-472C-4C32-B85F-5841AD5674E7").subscriptions.find(s => s.id === "5327B621-1D91-42B0-934E-DCD05981001F");
    this.sixMonthsSub = this.appContext.products.find(p => p.id === "00CE0D37-472C-4C32-B85F-5841AD5674E7").subscriptions.find(s => s.id === "3D935081-7384-4ADD-8481-88F62EECD24A");
    this.twelveMonthsSub = this.appContext.products.find(p => p.id === "00CE0D37-472C-4C32-B85F-5841AD5674E7").subscriptions.find(s => s.id === "BF89E441-C3D3-43B7-97F3-F623CE1F1B2E");

  }
  onSubmit(subId: string) {

    if (this.isLoggedIn) {
      this.subPurchaseModal(subId);
    }
    else {
      this._router.navigate(['signin']);
    }
  }

  Refresh() {
    window.location.reload();
  }

  smartOddsSubOptions() {
    this._router.navigate(['suboptions']);
  }

  subPurchaseModal(subId: string) {
    this.appContext.productId = "00CE0D37-472C-4C32-B85F-5841AD5674E7";
    this.appContext.subscriptionId = subId;
    this._sharingService.setMessage(this.appContext);
    this._router.navigate(['subpurchase']);
  }

  home() {
    this._router.navigate(['home'])
  }

  subOptions() {
    this._router.navigate(['suboptions'])
  }

}
