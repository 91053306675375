import { Component, OnInit, Inject } from '@angular/core';
import { Product } from '../models/product';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { CollectionResponse } from '../models/collection-response';
import { Context, context } from '../models/context';
import { SharingService } from '../Services/sharing-service';
import { SingleResponse } from '../models/single-response';
import { HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core/testing';
import { DOCUMENT } from '@angular/common';
import { StorageFile } from '../models/storage-file';

@Component({
  selector: 'app-existing-products',
  templateUrl: './existing-products.component.html',
  styleUrls: ['./existing-products.component.css']
})
export class ExistingProductsComponent implements OnInit {
  existingProducts = new Array<Product>();
  appContext: Context;
  selectedImage: StorageFile;
  title: string;
  imagesOnCloud = new Array<StorageFile>();
  filesOnCloud = new Array<StorageFile>();
  constructor(private _service: RacemasterApiService, private _sharingService: SharingService<Context>, @Inject(DOCUMENT) document) {
  }


  ngOnInit() {
    this._service.getAll<Product>("Products").subscribe(
      (data: CollectionResponse<Product>) => { this.existingProducts = data.collection; },
      (err) => { alert(err.message); },
      () => {
        if (this.existingProducts.length == 0) {
          this.title = "No Products";
        }
        else {
          this.title = "Existing Products";
        }
        this.appContext = this._sharingService.getMessage();
      }
    );
    this._service.getAll<StorageFile>("FileStorage").subscribe(
      (data: CollectionResponse<StorageFile>) => {
        // storage file contains many types of files, so we filter collection returned so that it only contains imgs.
        this.imagesOnCloud = data.collection.filter(file => file.name.endsWith(".png") || file.name.endsWith(".jpg"));
        this.filesOnCloud = data.collection.filter(file => file.name.endsWith(".exe") || file.name.endsWith(".zip") || file.name.endsWith(".apk"))
      }
    )
  }
  onSubmit(product: Product) {
    product.description = document.getElementById("description").innerText;
    //product.downloadUrl = document.getElementById("downloadUrl").innerText;
    product.name = document.getElementById("name").innerText;
    product.shortDescription = document.getElementById("shortDescription").innerText;

    this._service.put<Product>(product, "Products").subscribe(
      (data: SingleResponse) => {
        if (!data.success) {
          if (data.statusCode == 403) {
            alert("Product not found");
          }
          else {
            alert("Unauthorized access");
          }
        }
      },
      (error) => {
        alert(error.message);
      },
      () => {
        alert("Product updated successfully!")
      }

    )
    this._sharingService.setMessage(this.appContext);
  }

  getProductImage(product: Product): string {
    return product.imageUrl;
  }
  //select image accepts two params, img: the image we choose, prod: selected product to change 
  selectImage(img: StorageFile, product: Product) {
  //to change image into dropdown upon selection
    product.imageUrl = img.downloadUrl;
  }

  onDelete(productModel: Product) {
    let result: string;
    this._service.delete<Product>(productModel.id, "Products").subscribe(
      (data: string) => { result = data; },
      (err) => { alert(err.message); },
      () => {
        alert(result);
        this.refresh();
      }
    )
  }
  refresh() {
    window.location.reload();
  }
}
