import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgotten-credentials',
  templateUrl: './forgotten-credentials.component.html',
  styleUrls: ['./forgotten-credentials.component.css']
})
export class ForgottenCredentialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
