import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductSubscription } from '../models/product-subscription';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { SingleResponse } from '../models/single-response';
import { Product } from '../models/product';
import { CollectionResponse } from '../models/collection-response';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-product-subscription',
  templateUrl: './product-subscription.component.html',
  styleUrls: ['./product-subscription.component.css']
})
export class ProductSubscriptionComponent implements OnInit {
  subscriptionForm: FormGroup;
  model = new ProductSubscription();
  productResponse: SingleResponse;
  allProducts = new Array<Product>();
  constructor(private _fb: FormBuilder, private _service: RacemasterApiService, @Inject(DOCUMENT) document) {
    this.subscriptionForm = _fb.group({
      Id: ["", Validators.required],
      Name: ["", Validators.required],
      productId: ["", Validators.required],
      Price: ["", Validators.required],
      validityPeriod: ["", Validators.required],
      description: ["", Validators.required]
    })
  }

  ngOnInit() {
    this._service.getAll<Product>("Products").subscribe(
      (data: CollectionResponse<Product>) => { this.allProducts = data.collection; },
      (error) => { alert(error.message); }
    );
  }
  onSubmit() {
    this.model.id = this.subscriptionForm.controls["Id"].value;
    this.model.name = this.subscriptionForm.controls["Name"].value;
    this.model.price = Number(this.subscriptionForm.controls["Price"].value);
    this.model.productId = this.subscriptionForm.controls["productId"].value;
    this.model.daysToExpire = Number(this.subscriptionForm.controls["validityPeriod"].value);

    this._service.post<ProductSubscription>(this.model, "PrductSubscriptions").subscribe(
      (data: SingleResponse) => {
      },
      (error) => { alert(error.message) },
      () => {
        alert("Product subscription updated successfully!")
      }
    )
  }

 
}
