import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { Context } from '../models/context';
import { SharingService } from '../Services/sharing-service';
import { ProductSubscription } from '../models/product-subscription';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smartodds-subs',
  templateUrl: './smartodds-subs.component.html',
  styleUrls: ['./smartodds-subs.component.css']
})
export class SmartoddsSubsComponent implements OnInit {
  isLoggedIn = false;
  appContext: Context;
  sub30Days: ProductSubscription;
  sub60Days: ProductSubscription;
  threeMonthsSub: ProductSubscription;
  twelveMonthsSub: ProductSubscription;
  constructor(private modalService: ModalService, private _sharingService: SharingService<Context>, private _router: Router) {
    this.appContext = this._sharingService.getMessage();
    this.isLoggedIn = this.appContext.activeUser != null;
  }

  ngOnInit() {
    this.sub30Days = this.appContext.products.find(p => p.id === "542B0611-E8C8-46E4-B092-DF7A321F1D3B").subscriptions.find(s => s.id === "02BEBD64-07D7-4AC9-AB11-9074551403C2");
    this.sub60Days = this.appContext.products.find(p => p.id === "542B0611-E8C8-46E4-B092-DF7A321F1D3B").subscriptions.find(s => s.id === "CAEE1C2E-C41E-443B-8D0F-75B54DE47CD4");
    this.threeMonthsSub = this.appContext.products.find(p => p.id === "542B0611-E8C8-46E4-B092-DF7A321F1D3B").subscriptions.find(s => s.id === "90D16EAD-8145-4574-B7B6-5C295563EB43");
    this.twelveMonthsSub = this.appContext.products.find(p => p.id === "542B0611-E8C8-46E4-B092-DF7A321F1D3B").subscriptions.find(s => s.id === "D7034013-31B1-493F-873A-CE8C58D8AFFD");


  }
  onSubmit(subId: string) {
    if (this.isLoggedIn) {
      this.subPurchaseModal(subId);
    }
    else {
      this._router.navigate(['signin'])    }
  }


  Refresh() {
    window.location.reload();
  }


  subPurchaseModal(subId: string) {
    this.appContext.productId = "542B0611-E8C8-46E4-B092-DF7A321F1D3B";
    this.appContext.subscriptionId = subId;
    this._sharingService.setMessage(this.appContext);
    this._router.navigate(['subpurchase']);
  }
  home() {
    this._router.navigate(['home']);
  }

  subOptions() {
    this._router.navigate(['suboptions']);
  }

}
