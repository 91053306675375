import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { SignInComponent } from '../sign-in/sign-in.component';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { SubPurchaseComponent } from '../sub-purchase/sub-purchase.component';
import { SharingService } from '../Services/sharing-service';
import { context, Context } from '../models/context';
import { ProductSubscription } from '../models/product-subscription';
import { ProductSubscriptionComponent } from '../product-subscription/product-subscription.component';
import { ProductsubscriptionoptionComponent } from '../productsubscriptionoption/productsubscriptionoption.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-racemaster-select',
  templateUrl: './racemaster-select.component.html',
  styleUrls: ['./racemaster-select.component.css']
})

export class RacemasterSelectComponent implements OnInit {
  appContext: Context;
  threeMonthsSub: ProductSubscription;
  twelveMonthsSub: ProductSubscription;
  isLoggedIn = false;
  constructor(private modalService: ModalService, private _sharingService: SharingService<Context>, private _router: Router) {
    this.appContext = this._sharingService.getMessage();
    this.isLoggedIn = this.appContext.activeUser != null;

  }

  ngOnInit() {
    this.threeMonthsSub = this.appContext.products.find(p => p.id === "A98AA626-0193-41DF-AAE9-FD2408A8074F").subscriptions.find(s => s.id === "3F648188-8F6D-427C-A3B7-6CC232B5F195");
    this.twelveMonthsSub = this.appContext.products.find(p => p.id === "A98AA626-0193-41DF-AAE9-FD2408A8074F").subscriptions.find(s => s.id === "6149E3D3-C9A2-4724-A923-96BD554DD73E");

  }
  displayOptions() {
    this.appContext.productId = "A98AA626-0193-41DF-AAE9-FD2408A8074F";
    this._router.navigate(['productsuboption']);

  }
  onSubmit(subId: string) {
    if (this.isLoggedIn) {
      this.subPurchaseModal(subId);
    }
    else {
      this.signIn();
    }
  }


  signIn() {
    this._router.navigate(['signin']);
  }


  subOptions() {
    this._router.navigate(['suboptions']);
  }


  subPurchaseModal(subId: string) {

    this.appContext.productId = "A98AA626-0193-41DF-AAE9-FD2408A8074F";
    this.appContext.subscriptionId = subId;
    this._sharingService.setMessage(this.appContext);
    this._router.navigate(['subpurchase']);

  }

  home() {
    this._router.navigate(['home'])
  }
}
