import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-money-back-guarantee',
  templateUrl: './money-back-guarantee.component.html',
  styleUrls: ['./money-back-guarantee.component.css']
})
export class MoneyBackGuaranteeComponent implements OnInit {

  constructor(private modalService : ModalService,
    private _router : Router
    ) { }

  ngOnInit() {
  }

 
  subOptions() {
    this._router.navigate(['suboptions']);
     }
}