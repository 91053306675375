import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { EmailComponent } from '../email/email.component';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {
  
  constructor(private modalService : ModalService, private _router : Router) { }

  ngOnInit() {
  }


  Email() {
    this._router.navigate(['email']);
  }

  home() {
    this._router.navigate(['home']);
  }	
  
   subOptions() {
   this._router.navigate(['suboptions']);
    }
}
