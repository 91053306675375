import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-suggested-betting-rules',
  templateUrl: './suggested-betting-rules.component.html',
  styleUrls: ['./suggested-betting-rules.component.css']
})
export class SuggestedBettingRulesComponent implements OnInit {

  constructor(private modalService: ModalService, private _router : Router) { }

  ngOnInit() {
  }


  subOptions() {
    this._router.navigate(['suboptions'])
     }

}
