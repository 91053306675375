import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { SubInfoComponent } from './sub-info/sub-info.component';
import { SubPurchaseComponent } from './sub-purchase/sub-purchase.component';
import { ProductsComponentComponent } from './products-component/products-component.component';
import { ExistingProductsComponent } from './existing-products/existing-products.component';
import { ProductSubscriptionComponent } from './product-subscription/product-subscription.component';
import { AllServersComponent } from './all-servers/all-servers.component';
import { AllChannelsComponent } from './all-channels/all-channels.component';
import { AllProductSubscriptionsComponent } from './all-product-subscriptions/all-product-subscriptions.component';
import { ServerAdminComponent } from './servers and channels/server-admin/server-admin.component';
import { ChannelAdminComponent } from './servers and channels/channel-admin/channel-admin.component';
import { AllAdminPagesComponent } from './all-admin-pages/all-admin-pages.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { SmartoddsPremSubsComponent } from './smartodds-prem-subs/smartodds-prem-subs.component';
import { RacemasterSuboptionsComponent } from './racemaster-suboptions/racemaster-suboptions.component';
import { SmartoddsPlusComponent } from './smartodds-plus/smartodds-plus.component';
import { RacemasterRatingsComponent } from './racemaster-ratings/racemaster-ratings.component';
import { RacemasterSelectComponent } from './racemaster-select/racemaster-select.component';
import { RacemasterPlusComponent } from './racemaster-plus/racemaster-plus.component';
import { SmartoddsSubsComponent } from './smartodds-subs/smartodds-subs.component';
import { SmartoddsSelectSubsComponent } from './smartodds-select-subs/smartodds-select-subs.component';
import { EmailComponent } from './email/email.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { EnterPasswordComponent } from './enter-password/enter-password.component';
import { AnytimeComponent } from './anytime/anytime.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { SuggestedBettingRulesComponent } from './suggested-betting-rules/suggested-betting-rules.component';
import { RacemasterPlusPerfComponent } from './racemaster-plus-perf/racemaster-plus-perf.component';
import { FAQComponent } from './faq/faq.component';
import { SubPaymentComponent } from './sub-payment/sub-payment.component';
import { AnytimeWindowsComponent } from './anytime-windows/anytime-windows.component';
import { AboutComponent } from './about/about.component';
import { SmartoddsHomeComponent } from './smartodds-home/smartodds-home.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { MoneyBackGuaranteeComponent } from './money-back-guarantee/money-back-guarantee.component';
import { ProductsubscriptionoptionComponent } from './productsubscriptionoption/productsubscriptionoption.component';
import { PrivacyPolicyComponent } from './privacy/privacy.component';

const routes: Routes = [{ path: 'home', component: HomeComponent },
{ path: 'user', component: UserComponent },
{ path: 'subinfo', component: SubInfoComponent },
{ path: 'subpurchase', component: SubPurchaseComponent },
{ path: 'server', component: ServerAdminComponent },
{ path: 'channel', component: ChannelAdminComponent },
{ path: 'product', component: ProductsComponentComponent },
{ path: 'existingproducts', component: ExistingProductsComponent },
{ path: 'productsub', component: ProductSubscriptionComponent },
{ path: 'allservers', component: AllServersComponent },
{ path: 'allchannels', component: AllChannelsComponent },
{ path: 'allproductsub', component: AllProductSubscriptionsComponent },
{ path: 'alladminpages', component: AllAdminPagesComponent },
{ path: 'forgottenpass', component: ForgottenPasswordComponent },
{ path: 'smartoddsplus', component: SmartoddsPlusComponent },
{ path: 'plus', component: RacemasterPlusComponent },
{ path: 'prem', component: SmartoddsPremSubsComponent },
{ path: 'suboptions', component: RacemasterSuboptionsComponent },
{ path: 'ratings', component: RacemasterRatingsComponent },
{ path: 'select', component: RacemasterSelectComponent },
{ path: 'smartodds', component: SmartoddsSubsComponent },
{ path: 'smartselect', component: SmartoddsSelectSubsComponent },
{ path: 'smartplus', component: SmartoddsPlusComponent },
{ path: 'smartprem', component: SmartoddsPremSubsComponent },
{ path: 'email', component: EmailComponent },
{ path: 'signin', component: SignInComponent },
{ path: 'enterpassword', component: EnterPasswordComponent },
{ path: 'subinfo', component: SubInfoComponent },
{ path: 'anytime', component: AnytimeComponent },
{ path: 'analysis', component: AnalysisComponent },
{ path: 'suggestedbetting', component: SuggestedBettingRulesComponent },
{ path: 'plusperf', component: RacemasterPlusPerfComponent },
{ path: 'faq', component: FAQComponent },
{ path: 'subpayment', component: SubPaymentComponent },
{ path: 'anytimewindows', component: AnytimeWindowsComponent },
{ path: 'about', component: AboutComponent },
{ path: 'smarthome', component: SmartoddsHomeComponent },
{ path: 'userguide', component: UserGuideComponent },
{ path: 'moneyback', component: MoneyBackGuaranteeComponent },
{ path: 'productsuboption', component: ProductsubscriptionoptionComponent },
{ path: 'privacy-policy', component: PrivacyPolicyComponent },




{ path: '**', component: HomeComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
