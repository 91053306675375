import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RacemasterSelectComponent } from './racemaster-select/racemaster-select.component';
import { DomService } from './Services/dom.service';
import { ModalService } from './Services/modal.service';
import { SignInComponent } from './sign-in/sign-in.component';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { EmailComponent } from './email/email.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { EnterPasswordComponent } from './enter-password/enter-password.component';
import { SuggestedBettingRulesComponent } from './suggested-betting-rules/suggested-betting-rules.component';
import { MoneyBackGuaranteeComponent } from './money-back-guarantee/money-back-guarantee.component';
import { RacemasterRatingsComponent } from './racemaster-ratings/racemaster-ratings.component';
import { RacemasterPlusComponent } from './racemaster-plus/racemaster-plus.component';
import { RacemasterPlusPerfComponent } from './racemaster-plus-perf/racemaster-plus-perf.component';
import { FAQComponent } from './faq/faq.component';
import { SmartoddsSubsComponent } from './smartodds-subs/smartodds-subs.component';
import { SmartoddsSelectSubsComponent } from './smartodds-select-subs/smartodds-select-subs.component';
import { SmartoddsPremSubsComponent } from './smartodds-prem-subs/smartodds-prem-subs.component';
import { RacemasterSuboptionsComponent } from './racemaster-suboptions/racemaster-suboptions.component';
import { SmartoddsSuboptionsComponent } from './smartodds-suboptions/smartodds-suboptions.component';
import { SubInfoComponent } from './sub-info/sub-info.component';
import { SubPurchaseComponent } from './sub-purchase/sub-purchase.component';
import { SubPaymentComponent } from './sub-payment/sub-payment.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { AnytimeComponent } from './anytime/anytime.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SmartoddsHomeComponent } from './smartodds-home/smartodds-home.component';
import { AboutComponent } from './about/about.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { SmartAnytimeComponent } from './smart-anytime/smart-anytime.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AnytimeWindowsComponent } from './anytime-windows/anytime-windows.component';
import { UserComponent } from './user/user.component';
import { RacemasterApiService } from './Services/racemaster-api.service';
import { LocationService } from './Services/location.service';
import { SharingService } from './Services/sharing-service';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { ProductsComponentComponent } from './products-component/products-component.component';
import { ServerAdminComponent } from './servers and channels/server-admin/server-admin.component';
import { ChannelAdminComponent } from './servers and channels/channel-admin/channel-admin.component';
import { ExistingProductsComponent } from './existing-products/existing-products.component';
import { ProductSubscriptionComponent } from './product-subscription/product-subscription.component';
import { AllServersComponent } from './all-servers/all-servers.component';
import { AllChannelsComponent } from './all-channels/all-channels.component';
import { AllProductSubscriptionsComponent } from './all-product-subscriptions/all-product-subscriptions.component';
import { AllAdminPagesComponent } from './all-admin-pages/all-admin-pages.component';
import { ForgottenCredentialsComponent } from './forgotten-credentials/forgotten-credentials.component';
import { SmartoddsPlusComponent } from './smartodds-plus/smartodds-plus.component';
import { ProductsubscriptionoptionComponent } from './productsubscriptionoption/productsubscriptionoption.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { PrivacyPolicyComponent } from './privacy/privacy.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RacemasterSelectComponent,
    SignInComponent,
    EmailComponent,
    ForgottenPasswordComponent,
    EnterPasswordComponent,
    SuggestedBettingRulesComponent,
    MoneyBackGuaranteeComponent,
    RacemasterRatingsComponent,
    RacemasterPlusComponent,
    RacemasterPlusPerfComponent,
    FAQComponent,
    SmartoddsSubsComponent,
    SmartoddsSelectSubsComponent,
    SmartoddsPremSubsComponent,
    RacemasterSuboptionsComponent,
    SmartoddsSuboptionsComponent,
    SmartoddsPlusComponent,
    SubInfoComponent,
    SubPurchaseComponent,
    SubPaymentComponent,
    AnalysisComponent,
    AnytimeComponent,
    ResetPasswordComponent,
    SmartoddsHomeComponent,
    AboutComponent,
    UserGuideComponent,
    SmartAnytimeComponent,
    AnytimeWindowsComponent,
    UserComponent,
    ServerAdminComponent,
    ProductsComponentComponent,
    ChannelAdminComponent,
    ExistingProductsComponent,
    ProductSubscriptionComponent,
    AllServersComponent,
    AllChannelsComponent,
    AllProductSubscriptionsComponent,
    AllAdminPagesComponent,
    ForgottenCredentialsComponent,
    ProductsubscriptionoptionComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    NgbModule,
    CarouselModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    HttpClientModule,
    NgxPayPalModule

  ],
  entryComponents: [
    RacemasterSelectComponent, SignInComponent, EmailComponent, ForgottenPasswordComponent, EnterPasswordComponent, MoneyBackGuaranteeComponent, SuggestedBettingRulesComponent, RacemasterSelectComponent, RacemasterRatingsComponent, RacemasterPlusComponent, RacemasterPlusPerfComponent, FAQComponent, SmartoddsPremSubsComponent, SmartoddsSelectSubsComponent, SmartoddsSubsComponent, RacemasterRatingsComponent, RacemasterSuboptionsComponent, SmartoddsSuboptionsComponent, SmartoddsPlusComponent, SubInfoComponent, SubPurchaseComponent, SubPaymentComponent, ProductsubscriptionoptionComponent,
    AnalysisComponent, AnytimeComponent, ResetPasswordComponent, SmartoddsHomeComponent, AboutComponent, UserGuideComponent, SmartAnytimeComponent, AnytimeWindowsComponent, ForgottenCredentialsComponent
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}, DomService, ModalService, SharingService, RacemasterApiService, LocationService, CookieService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
