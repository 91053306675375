export class WebsiteUpdates {
    public accumulatedProfitOnTurnover: number;
    public racesCount: number;
    public raceDaysCount: number;
    public span: number;
    public averageWinRate: number;
    public staggeringProfitOnTurnover: number;
    public winningRacesCount: number;
    public selectedRacesCount: number;
    public yearsCount: number;
 }
 