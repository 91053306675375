import { Component } from '@angular/core';
import { ModalService } from './Services/modal.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'project';
  constructor(private modalService : ModalService){

  }
  ngOnInit() {
    document.body.classList.add("bg-img");
  }
  public close() {
    this.modalService.destroy();
  }
}  
