// To parse this data:
//
//   import { Convert, Server } from "./file";
//
//   const server = Convert.toServer(json);

export class Server {
    constructor() { }
    public id: string;
    public portNumber: number;
    public serverAddress: string;
    public name: string;
    public firstLineText: string;
    public secondLineText: string;
    
}

