import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '../Services/modal.service';
import { EmailComponent } from '../email/email.component';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.css']
})
export class ForgottenPasswordComponent implements OnInit {
  form: FormGroup;
  pinForm: FormGroup;
  passMatch = true;
  isOldPassShown = false;
  isPassShown = false;
  isConfirmPassShown = false;
  emptyEmail = false;
  showError = false;
  emptyInputs = false;
  constructor(fb: FormBuilder, private _router: Router, private modalService: ModalService) {
    this.form = fb.group({
      email: ["", Validators.required]
    });
    this.pinForm = fb.group({
      pinCode: ["", Validators.required],
      oldPassword: ["", [Validators.required, Validators.minLength(6)]],
      newPassword: ["", [Validators.required, Validators.minLength(6)]],
      confirmpassword: ["", Validators.required],
      enterpinnum1: ["", Validators.required],
      enterpinnum2: ["", Validators.required],
      enterpinnum3: ["", Validators.required],
      enterpinnum4: ["", Validators.required],
      confirmpin1: ["", Validators.required],
      confirmpin2: ["", Validators.required],
      confirmpin3: ["", Validators.required],
      confirmpin4: ["", Validators.required],

    });
  }
  ngOnInit() {
    this.form.controls["email"].valueChanges.subscribe(x => this.showError = false);
  }
  onSubmit() {
    if (this.form.controls["email"].value == "") {
      this.emptyEmail = true;
      return;
    }
    if (this.form.controls["email"].errors != null) {
      //show error delays error message till user click on submit button as it checks first if there are errors on email control
      this.showError = true;
      return
    }
  }
  onReset() {
    if (this.pinForm.controls["newPassword"].value === this.pinForm.controls["confirmpassword"].value) {
      this.passMatch = true;

    }
    else {
      this.passMatch = false;
      return;

    }
    var oldPassword = this.pinForm.controls["oldPassword"].value;
    var newPassword = this.pinForm.controls["newPassword"].value;
    var confirmpassword = this.pinForm.controls["confirmpassword"].value;
    var pinNumber = this.pinForm.controls["enterpinnum1"].value
      .concat(this.pinForm.controls["enterpinnum2"].value)
      .concat(this.pinForm.controls["enterpinnum3"].value)
      .concat(this.pinForm.controls["enterpinnum4"].value);

    var confirmPin = this.pinForm.controls["confirmpin1"].value
      .concat(this.pinForm.controls["confirmpin2"].value)
      .concat(this.pinForm.controls["confirmpin3"].value)
      .concat(this.pinForm.controls["confirmpin4"].value)

    if (oldPassword.length == 0 || newPassword.length == 0 || confirmpassword.length == 0 || pinNumber.length == 0 || confirmPin.length == 0) {
      this.emptyInputs = true;
      return;
    }
  }
  toggleOldPassword($event) {
    var imageTag = document.getElementById("toggleOldPasswordImg");
    var inputTag1 = document.getElementById("oldPassword");
    this.isOldPassShown = !this.isOldPassShown;
    if (this.isOldPassShown) {
      // Case visible 
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Open.png");
      }
      if (inputTag1 != null) {
        inputTag1.setAttribute("type", "text");

      }
    }
    else {
      // Case Invisible
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Closed.png");
      }
      if (inputTag1 != null) {
        inputTag1.setAttribute("type", "password");
      }
    }
  }
  toggleNewPassword($event) {
    var imageTag = document.getElementById("toggleNewPasswordImg");
    var inputTag1 = document.getElementById("newPassword");
    this.isPassShown = !this.isPassShown;
    if (this.isPassShown) {
      // Case visible 
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Open.png");
      }
      if (inputTag1 != null) {
        inputTag1.setAttribute("type", "text");

      }
    }
    else {
      // Case Invisible
      if (imageTag != null) {
        imageTag.setAttribute("src", "assets/Password Eye Closed.png");
      }
      if (inputTag1 != null) {
        inputTag1.setAttribute("type", "password");
      }
    }
  }
  toggleConfirmPassword($event) {
    var imageTag2 = document.getElementById("toggleConfirmPasswordImg");
    var inputTag2 = document.getElementById("confirmpassword");
    this.isConfirmPassShown = !this.isConfirmPassShown;
    if (this.isConfirmPassShown)
    // case visible
    {
      if (imageTag2 != null) {
        imageTag2.setAttribute("src", "assets/Password Eye Open.png");
      }
      if (inputTag2 != null) {
        inputTag2.setAttribute("type", "text")
      }
    }
    else {
      // Case Invisible
      if (imageTag2 != null) {
        imageTag2.setAttribute("src", "assets/Password Eye Closed.png");
      }
      if (inputTag2 != null) {
        inputTag2.setAttribute("type", "password");
      }
    }
  }
  public close() {
    this.modalService.destroy();
  }
  emailModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(EmailComponent, inputs, {});
  }
  Email() {
    this.close();
    this.emailModal();
  }
}
