import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { SubPurchaseComponent } from '../sub-purchase/sub-purchase.component';
import { Context, context } from '../models/context';
import { SharingService } from '../Services/sharing-service';
import { SignInComponent } from '../sign-in/sign-in.component';
import { ProductSubscription } from '../models/product-subscription';
import { Router } from '@angular/router';

@Component({
  selector: 'app-racemaster-plus',
  templateUrl: './racemaster-plus.component.html',
  styleUrls: ['./racemaster-plus.component.css']
})
export class RacemasterPlusComponent implements OnInit {
  appContext: Context;
  threeMonthsSub: ProductSubscription;
  twelveMonthsSub: ProductSubscription;
  isLoggedIn = false;
  constructor(private modalService: ModalService, private _sharingService: SharingService<Context>, private _router: Router) {
    this.appContext = this._sharingService.getMessage();
    this.isLoggedIn = this.appContext.activeUser != null;

  }

  ngOnInit() {
    this.threeMonthsSub = this.appContext.products.find(p => p.id === "4B89B322-CDBE-4952-8BF0-A9AADE079B8F").subscriptions.find(s => s.id === "FCB0BCF1-4269-4B93-8D52-531869A16E8D");
    this.twelveMonthsSub = this.appContext.products.find(p => p.id === "4B89B322-CDBE-4952-8BF0-A9AADE079B8F").subscriptions.find(s => s.id === "CA441DAD-27A6-4DAD-8E7B-EC98358AD877");

  }
  onSubmit(subId: string) {
    if (this.isLoggedIn) {
      this.subPurchaseModal(subId);
    }
    else {
      this._router.navigate(['signin']);    }
  }

  home() {
    this._router.navigate(['home'])
  }

  subOptions() {
    this._router.navigate(['suboptions'])
  }
  subPurchaseModal(subId: string) {
    this.appContext.productId = "4B89B322-CDBE-4952-8BF0-A9AADE079B8F";
    this.appContext.subscriptionId = subId;
    this._sharingService.setMessage(this.appContext);
    this._router.navigate(['subpurchase']);
  }

}
