import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CollectionResponse } from '../models/collection-response';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { UserClaim } from '../models/user-claim';
import { SingleResponse } from '../models/single-response';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  userForm: FormGroup;
  allUsers: CollectionResponse<User> = new CollectionResponse<User>();
  user = new User(); // Initialization to avoid errors in html 

  constructor(fb: FormBuilder, private _racemasterService: RacemasterApiService) {
    this.userForm = fb.group({
      email: ["", Validators.required],
      userClaims: ["", Validators.required],
    })

  }

  ngOnInit() {
    // Call the web service
    this._racemasterService.getAll<User>("Users").subscribe((data: CollectionResponse<User>) => {
      // Service got information for us
      if (data.success) {
        this.allUsers = data;
      }
      else {
        alert("Forbidden request, you don't have access...")
      }
    },
      error => {
        // Service call failure (No internet, etc....)
        alert(error);
      },
      () => {
        this.userForm.controls['userClaims'].reset("Choose...");
      });
  }
  onSubmit() {
    var claim = this.user.userClaims.find(c => c.claimName === "UserMode");

    if (this.userForm.controls['userClaims'].value == "Choose...") {
      alert("Please select user claim");
      return;
    }

    claim.claimValue = this.userForm.controls['userClaims'].value.toString();

    this._racemasterService.put<User>(this.user, "Users").subscribe(
      (data: SingleResponse) => { },
      (error) => { alert(error); },
      () => {
        alert("User is updated successfully")
      }
      );
  }

  onSelectUser(event) {
    this.user = this.allUsers.collection.find(u => u.email == event.target.value);
  }

}
