import { UserClaim } from './user-claim';

export class User {
    public id: string;
    public userName: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phoneNumber: string;
    public location: string;
    public joinDate: Date;
    public userClaims: UserClaim[];
    public password: string;
    public pinCode: string;

    constructor() {
        this.userClaims = new Array<UserClaim>();
    }
}
