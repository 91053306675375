import { Component, OnInit, Inject, ViewChild, AfterViewInit, AfterContentChecked } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../Services/modal.service';
import { from } from 'rxjs';

import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { EnterPasswordComponent } from '../enter-password/enter-password.component';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { SingleResponse } from '../models/single-response';
import { StorageFile } from '../models/storage-file';

import { Product } from '../models/product';
import { CollectionResponse } from '../models/collection-response';
import { WebsiteUpdates } from '../models/website-updates';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare const myTest: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @ViewChild('ngcarousel', { static: true }) ngCarousel: NgbCarousel;
  @ViewChild('racemaster', { static: true }) Racemaster: any;
  @ViewChild('racemaster-select', { static: true }) racemasterSelect: any;

  yearDifference: number;
  closeResult: string;
  carouselCycle = true;
  learnmoreopen = false;
  subscribenow = true;
  demonstrateopen = false;
  lastweekopen = false;
  moneybackopen = false;
  ratingsopen = false;
  suboptionsopen = false;
  // we created instance of context only in home component because we bind it in HTML
  appContext = new Context();
  isAdmin = false;
  dynamicTxt = new WebsiteUpdates();
  model: SingleResponse;
  collectionResponse = new CollectionResponse<Product>();
  webSiteUpdates = new WebsiteUpdates();

  constructor(
    @Inject(DOCUMENT) document,
    private modalService: ModalService,
    private _sharingService: SharingService<Context>,
    private _service: RacemasterApiService,
    private _cookieService: CookieService,
    private _router: Router,
    private _http: HttpClient) {
    this.pastYearsCalc();
    // filling active user with cookie service
    this.appContext.activeUser.userName = this._cookieService.get("user_name");
    this.appContext.activeUser.firstName = this._cookieService.get("first_name");
    this.appContext.activeUser.lastName = this._cookieService.get("last_name");
    this.appContext.activeUser.phoneNumber = this._cookieService.get("phone_number");
    this.appContext.activeUser.email = this._cookieService.get("email_address");
    this.appContext.activeUser.id = this._cookieService.get("user_id");

    if (document.width < 800) {
      this.appContext.isMobile = true;
    }

    this._service.getAll<Product>("Products").subscribe(
      (data: CollectionResponse<Product>) => { this.appContext.products = data.collection },
      (error) => { alert(error.message) },
      () => {
        // replacing products that are sorted in appcontext.products with appcontext that has no order
        this.appContext.products = this.appContext.products.sort((a, b) => a.displayOrder - b.displayOrder);
        this.appContext.products.forEach(p => p.subscriptions = p.subscriptions.sort((a, b) => a.displayOrder - b.displayOrder))
        this._sharingService.setMessage(this.appContext);
      }
    )
  }
  // Imp Note: in home component only we create an instance of app context to be able to avoid errors of not finding context in index
  // as it(home) is the index page, we create a property of context in other components just to be able to store the data.   
  ngOnInit() {
    if (!this.appContext.activeUser.userName) {
      this.appContext.activeUser = null;
    }
    else {
      if (this._cookieService.get("admin") === "true") {
        this.isAdmin = true;
      }
    }

    this._sharingService.setMessage(this.appContext);
    this.appContext = this._sharingService.getMessage();

    this._service.getFileInfo("cb5c5fc8-ac84-4647-923b-80518d32862a").subscribe(
      (data: StorageFile) => { 
        this._service.readFile(data.downloadUrl).subscribe(
          (data: any) => { 
            let internalFileUrl = this._service.createFileBlobUrl<WebsiteUpdates>(data);

            this._service.downloadFile(internalFileUrl).subscribe(
              (data: WebsiteUpdates) => { this.webSiteUpdates = data; }
            );
          }
        )
      }
    );  

   
  }
  moneyBack() {
    this._router.navigate(['moneyback']);
  }
  Email() {
    this._router.navigate(['email']);
  }
  onLearnMore(productId: string) {
    if (productId === "C723BA71-A776-43C2-B6D9-5A9044476077") { this._router.navigate(['ratings']); }
    else if (productId === "A98AA626-0193-41DF-AAE9-FD2408A8074F") { this._router.navigate(['select']); }
    else if (productId === "4B89B322-CDBE-4952-8BF0-A9AADE079B8F") { this._router.navigate(['plus']); }
    else if (productId === "542B0611-E8C8-46E4-B092-DF7A321F1D3B") { this._router.navigate(['smartodds']); }
    else if (productId === "00CE0D37-472C-4C32-B85F-5841AD5674E7") { this._router.navigate(['smartselect']); }
    else if (productId === "FEDBC6A3-25EF-4D39-B012-D2B727108632") { this._router.navigate(['smartplus']); }
    else if (productId === "FEDBC6A3-25EF-4D39-B012-D2B727108632") { this._router.navigate(['smartplus']); }
    else if (productId === "0C1E52F6-DFC6-4A2D-B2BE-507204C137D5") { this._router.navigate(['smartprem']); }
  }

  onAdminPages() {
    this._router.navigate(['alladminpages']);
  }
  signOut() {
    this._cookieService.delete("user_name");
    this._cookieService.delete("user_token");
    this._cookieService.delete("user_refresh_token");

    this.Refresh();
  }
  stopCarousel() {
    this.carouselCycle = !this.carouselCycle;
    if (!this.carouselCycle) {
      this.ngCarousel.pause();
    }

  }
  goToSlide(slide) {
    this.ngCarousel.select(slide);
  }

  pastYearsCalc() {
    var currentYear = new Date().getFullYear();
    this.yearDifference = currentYear - 2008;
  }
  signIn() {
    this._router.navigate(['signin']);
  }
  resetModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(ResetPasswordComponent, inputs, {});
  }

  Refresh() {
    window.location.reload();
  }


  suggestedBetting() {
    this._router.navigate(['suggestedbetting']);
  }

  Select() {

    this._router.navigate(['select']);
  }

  Ratings() {

    this._router.navigate(['ratings']);
  }

  Plus() {
    this._router.navigate(['plus']);
  }

  plusPerf() {

    this._router.navigate(['plusperf']);
  }

  FAQ() {

    this._router.navigate(['faq']);
  }


  subOptions() {
    this._router.navigate(['suboptions']);
  }


  subInfo() {

    this._router.navigate(['subinfo']);
  }

  subPurchase() {

    this._router.navigate(['subinfo']);
  }

  subPayment() {
    this._router.navigate(['subpayment']);

  }

  analysis() {

    this._router.navigate(['analysis']);

  }

  anytime() {

    this._router.navigate(['anytime']);

  }

  anytimeWindows() {

    this._router.navigate(['anytimewindows']);
  }

  smartAnytime() {

    this._router.navigate(['anytime']);

  }
  smartHome() {
    this._router.navigate(['smarthome'])
  }

  about() {

    this._router.navigate(['about']);
  }


}
