import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.css']
  })
  export class PrivacyPolicyComponent  implements OnInit
  {
    constructor( private _router : Router){
        
    }
    ngOnInit() {
       
    }
    home() {
        this._router.navigate(['home'])
      }
    Email() {

        this._router.navigate(['email']);
        }

  }