import { Injectable } from '@angular/core';
import { JsonProperty, Serializable, deserialize, serialize } from 'typescript-json-serializer';

@Injectable({
  providedIn: 'root'
})
export class SharingService<T> {
  constructor() { }

  setMessage(subjectSource: T) {
    var json = serialize(subjectSource, true);

    localStorage.setItem("sharedData", JSON.stringify(json));
  }

  getMessage() : T{
    return JSON.parse(localStorage.getItem("sharedData"));
  }

  // private messageSource = new BehaviorSubject<Context>(null);
  // currentMessage = this.messageSource.asObservable();

  // constructor() { }

  // setMessage(subjectSource: Context) {
  //   this.messageSource.next(subjectSource);
  // }
}
