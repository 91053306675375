import { Component, OnInit } from '@angular/core';
import { context, Context } from '../models/context';
import { Product } from '../models/product';
import { ModalService } from '../Services/modal.service';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { SharingService } from '../Services/sharing-service';

@Component({
  selector: 'app-productsubscriptionoption',
  templateUrl: './productsubscriptionoption.component.html',
  styleUrls: ['./productsubscriptionoption.component.css']
})
export class ProductsubscriptionoptionComponent implements OnInit {
  context: Context;
  product: Product;
  constructor(private _context: Context, private _api: RacemasterApiService, private _sharingService: SharingService<Context>, private _modalService: ModalService) {
    this._sharingService.getMessage();
  }

  ngOnInit() {
    var inputs = this._modalService.getInputs();
    this.product = this._context.products.find(p => p.id == inputs.selectedProductId)
  }
}
