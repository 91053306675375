import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { Country } from "../models/country";
@Injectable()
export class LocationService {
  constructor(private _http: HttpClient) {}
  getLocations(): Observable<Country[]> {
    return this._http.get<Country[]>("https://restcountries.com/v2/all");
  }
}


