import { Component, OnInit, Inject } from '@angular/core';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { Channel } from '../models/channel';
import { CollectionResponse } from '../models/collection-response';
import { DOCUMENT } from '@angular/common';
import { SingleResponse } from '../models/single-response';
import { error } from 'protractor';

@Component({
  selector: 'app-all-channels',
  templateUrl: './all-channels.component.html',
  styleUrls: ['./all-channels.component.css']
})
export class AllChannelsComponent implements OnInit {
  allChannels = new Array<Channel>();
  channelsModel: Channel;
  constructor(private _service: RacemasterApiService, @Inject(DOCUMENT) document) { }

  ngOnInit() {
    this._service.getAll<Channel>("Channels").subscribe(
      (data: CollectionResponse<Channel>) => { this.allChannels = data.collection },
      (error) => { alert(error.message) }
    )
  }
  onSubmit() {
    this.channelsModel = new Channel();

    this.channelsModel.firstLine = document.getElementById("firstLine").innerText;
    this.channelsModel.id = document.getElementById("id").innerText;
    this.channelsModel.name = document.getElementById("name").innerText;
    this.channelsModel.secondLine = document.getElementById("secondLine").innerText;
    this.channelsModel.serverId = document.getElementById("serverId").innerText;

    this._service.put<Channel>(this.channelsModel, "Channels").subscribe(
      (data: SingleResponse) => { },
      (err) => { alert(err.message); },
      () => { alert("Channel is successfuly updated!") }
    )

  }
  onDelete(channelsModel : Channel) {
    let result : string;
    this._service.delete<Channel>(this.channelsModel.id,"Channels").subscribe(
      (data : string) => {result = data},
      (error) => {alert(error.message)},
      ()=> {alert(result);
      this.refresh();}
    )
    

  }
  refresh() {
    window.location.reload();
  }
}
