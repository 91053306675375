import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { AboutComponent } from '../about/about.component';
import { SmartoddsSuboptionsComponent } from '../smartodds-suboptions/smartodds-suboptions.component';
import { AnytimeComponent } from '../anytime/anytime.component';
import { UserGuideComponent } from '../user-guide/user-guide.component';
import { SignInComponent } from '../sign-in/sign-in.component';
import { EmailComponent } from '../email/email.component';
import { SmartAnytimeComponent } from '../smart-anytime/smart-anytime.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smartodds-home',
  templateUrl: './smartodds-home.component.html',
  styleUrls: ['./smartodds-home.component.css']
})
export class SmartoddsHomeComponent implements OnInit {

  constructor( private modalService: ModalService, 
    private _router : Router) { }

  ngOnInit() {
  }
  // cookieValue = this._cookieService.get('user');

  
  About() {
    this._router.navigate(['about']);
  }
 
  smartOddsSubOptions() {
    this._router.navigate(['suboptions']);
  }

  smartAnytime() {
    this._router.navigate(['anytime']);
  }
 
  smartOddsUserGuide() {
    this._router.navigate(['userguide']);
  }
 

  signIn() {
    this._router.navigate(['signin']);
  }


 
  Email() {
    this._router.navigate(['email']);
  }
}




