import { Component, OnInit, Inject } from '@angular/core';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { DOCUMENT } from '@angular/common';
import { ProductSubscription } from '../models/product-subscription';
import { CollectionResponse } from '../models/collection-response';
import { SingleResponse } from '../models/single-response';
import { Router } from '@angular/router';
import { error } from 'protractor';

@Component({
  selector: 'app-all-product-subscriptions',
  templateUrl: './all-product-subscriptions.component.html',
  styleUrls: ['./all-product-subscriptions.component.css']
})
export class AllProductSubscriptionsComponent implements OnInit {
  allProductSubs = new Array<ProductSubscription>();
  productsubsModel: ProductSubscription;
  constructor(private _service: RacemasterApiService, @Inject(DOCUMENT) document, private _router: Router) { }

  ngOnInit() {
    this._service.getAll<ProductSubscription>("ProductSubscriptions").subscribe(
      (data: CollectionResponse<ProductSubscription>) => { this.allProductSubs = data.collection },
      (error) => { alert(error.message) }
    )
  }
  onSubmit() {
    this.productsubsModel = new ProductSubscription();

    this.productsubsModel.id = document.getElementById("Id").innerText;
    this.productsubsModel.name = document.getElementById("Name").innerText;
    this.productsubsModel.price = Number(document.getElementById("Price").innerText);
    this.productsubsModel.productId = document.getElementById("productId").innerText;
    this.productsubsModel.daysToExpire = Number(document.getElementById("validityPeriod").innerText);

    this._service.put<ProductSubscription>(this.productsubsModel, "ProductSubscriptions").subscribe(
      (data: SingleResponse) => { },
      (error) => { alert(error.message) },
      () => {
        alert("Product Subscription is updated successfully!");
        this._router.navigate(["alladminpages"]);
      }
    )
  }
  onDelete(productsubsModel: ProductSubscription) {
    let result: string;
    this._service.delete<ProductSubscription>(productsubsModel.id, "ProductSubscriptions").subscribe(
      (data: string) => { result = data },
      (error) => { alert(error.message) },
      () => {
        alert(result);
        this.refresh();
      }
    )
  }
  refresh() {
    window.location.reload();
  }

}
