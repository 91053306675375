export class StorageFile {
    [x: string]: any;
    public id: string;
    public name: string;
    public bucketUrl: string;
    public storagePath: string;
    public downloadUrl : string;
    constructor(){
    }
}
