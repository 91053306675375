import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { AboutComponent } from '../about/about.component';
import { AnytimeComponent } from '../anytime/anytime.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.css']
})
export class UserGuideComponent implements OnInit {

  constructor(private modalService: ModalService,
    private _router: Router) { }

  ngOnInit() {
  }



  home() {
    this._router.navigate(['home']);
  }	
  About() {
    this._router.navigate(['about']);
  }

  anytime() {
    this._router.navigate(['anytime']);
  }
}



