import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { SmartoddsSuboptionsComponent } from '../smartodds-suboptions/smartodds-suboptions.component';
import { SubPurchaseComponent } from '../sub-purchase/sub-purchase.component';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { SignInComponent } from '../sign-in/sign-in.component';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { Product } from '../models/product';
import { CollectionResponse } from '../models/collection-response';
import { ProductSubscription } from '../models/product-subscription';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smartodds-prem-subs',
  templateUrl: './smartodds-prem-subs.component.html',
  styleUrls: ['./smartodds-prem-subs.component.css']
})
export class SmartoddsPremSubsComponent implements OnInit {
  isLoggedIn = false;
  appContext: Context;
  oneMonth : ProductSubscription;
  threeMonths : ProductSubscription;
  twelveMonths : ProductSubscription;
  constructor(private modalService: ModalService, private _sharingService: SharingService<Context>, private _service: RacemasterApiService,private _router : Router) {
    this.appContext = this._sharingService.getMessage();
    this.isLoggedIn = this.appContext.activeUser != null;

   }

  ngOnInit() {
    this.oneMonth = this.appContext.products.find(p => p.id === "F8CC07A4-ECA3-43C4-A183-43CC2320C925").subscriptions.find(s => s.id === "C05E7D84-9F85-4B30-ACEF-3B4BF4B3531F");
    this.threeMonths = this.appContext.products.find(p => p.id === "F8CC07A4-ECA3-43C4-A183-43CC2320C925").subscriptions.find(s => s.id === "5BEF3CEE-C34A-41FA-A096-38AE9F94B918");
    this.twelveMonths = this.appContext.products.find(p => p.id === "F8CC07A4-ECA3-43C4-A183-43CC2320C925").subscriptions.find(s => s.id === "0C1E52F6-DFC6-4A2D-B2BE-507204C137D5");


  }
  onSubmit(subId : string) {
    if (this.isLoggedIn) {
      this.subPurchaseModal(subId);
    }
    else {
      this._router.navigate(['signin']);
    }
  }
 


  smartOddsSubOptions() {
    this._router.navigate(['suboptions']);
  }

  home() {
    this._router.navigate(['home'])
  }
  
   subOptions() {
   this._router.navigate(['suboptions'])
    }
  
  
    subPurchaseModal(subId: string) {
      this.appContext.productId = "0C1E52F6-DFC6-4A2D-B2BE-507204C137D5";
      this.appContext.subscriptionId = subId;
      this._sharingService.setMessage(this.appContext);
      this._router.navigate(['subpurchase']);
    }
}
