import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { AnalysisComponent } from '../analysis/analysis.component';
import { AnytimeWindowsComponent } from '../anytime-windows/anytime-windows.component';


@Component({
  selector: 'app-smart-anytime',
  templateUrl: './smart-anytime.component.html',
  styleUrls: ['./smart-anytime.component.css']
})
export class SmartAnytimeComponent implements OnInit {

  constructor(private modalService: ModalService) { }

  ngOnInit() {
  }
  public close() {
    this.modalService.destroy();
  }

  analysisModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(AnalysisComponent, inputs, {});
  }
  analysis() {
    this.close();
    this.analysisModal();
  }
  anytimeWindows() {
    this.close();
    this.anytimeWindowsModal();
  }
  anytimeWindowsModal() {
    let inputs = {
      isMobile: false
    }
    this.modalService.init(AnytimeWindowsComponent, inputs, {});

  }
}