
export class ProductSubscription {
    public id: string;
    public name: string;
    public productId: string;
    public price: number;
    public daysToExpire: number;
    public displayOrder: number;
    public displayText: string;
    public description: string;
}
