import { Component, OnInit } from '@angular/core';
import { Channel } from '../../models/channel';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { RacemasterApiService } from 'src/app/Services/racemaster-api.service';
import { CollectionResponse } from 'src/app/models/collection-response';
import { Server } from '../../models/server';
import { UserClaim } from 'src/app/models/user-claim';

@Component({
  selector: 'app-channel-admin',
  templateUrl: './channel-admin.component.html',
  styleUrls: ['./channel-admin.component.css']
})
export class ChannelAdminComponent implements OnInit {
  channelModel: Channel;
  channelForm: FormGroup;
  // allServers is an instance of collection response that gets filled with Api response (data) in the ngoninit method
  allServers: CollectionResponse<Server> = new CollectionResponse<Server>();
  channel = new Channel();

  constructor(fb: FormBuilder, private _racemasterService: RacemasterApiService) {
    this.channelForm = fb.group({
      serverId: ["", Validators.required],
      id: ["", Validators.required],
      name: ["", Validators.required],
      firstLine: ["", Validators.required],
      secondLine: ["", Validators.required]
    })

  }

  ngOnInit() {
    // Call the web service
    this._racemasterService.getAll<Server>("Servers").subscribe((data: CollectionResponse<Server>) => {
      // Service got information for us
      if (data.success) {
        this.allServers = data;
      }
      else {
        alert("Forbidden request, you don't have access...")
      }
    },
      error => {
        // Service call failure (No internet, etc....)
        alert(error);
      },
      () => {
        // Service call completed without errors and we have our instance set
      });
  }
  onSubmit() {
    this.channel.serverId = this.channelForm.controls['serverId'].value;
    this.channel.id = this.channelForm.controls['id'].value;
    this.channel.name = this.channelForm.controls['name'].value;
    this.channel.firstLine = this.channelForm.controls['firstLine'].value;
    this.channel.secondLine = this.channelForm.controls['secondLine'].value;

    this._racemasterService.post<Channel>(this.channel, "Channels");
  }

}