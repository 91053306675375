import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-all-admin-pages',
  templateUrl: './all-admin-pages.component.html',
  styleUrls: ['./all-admin-pages.component.css']
})
export class AllAdminPagesComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit() {
  }
onServer(){
  this._router.navigate(["server"]);
}
onChannel(){
  this._router.navigate(["channel"]);
}
onProduct(){
  this._router.navigate(["product"]);
}
onExistingProducts(){
  this._router.navigate(["existingproducts"]);
}
onProductSub(){
  this._router.navigate(["productsub"]);
}
onAllServers(){
  this._router.navigate(["allservers"]);
}
onAllChannels(){
  this._router.navigate(["allchannels"]);
}
onAllProductSub(){
  this._router.navigate(["allproductsub"]);
}
onUsers(){
  this._router.navigate(["user"]);
}
}
