import { ProductsubscriptionoptionComponent } from '../productsubscriptionoption/productsubscriptionoption.component';

export class UserSubscription {
    public id: string;
    public userId: string;
    public productId: string;
    public subscriptionId: string;
    public startDate: Date;
    public paymentType: number;
    public paypalOrderId: string;
    public paypalPayerId: string;
    public productOptions: string[];
    public price : number;

    constructor() {
        this.productOptions = new Array<string>();
    }
}
