import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { EmailComponent } from '../email/email.component';
import { AnalysisComponent } from '../analysis/analysis.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-anytime',
  templateUrl: './anytime.component.html',
  styleUrls: ['./anytime.component.css']
})
export class AnytimeComponent implements OnInit {

  constructor(private modalService: ModalService, private _router: Router) { }

  ngOnInit() {
  }



  Email() {
    this._router.navigate(['email']);
  }


  analysis() {
    this._router.navigate(['analysis']);

  }

  home() {
    this._router.navigate(['home']);
  }
}
