import { Component, OnInit } from '@angular/core';
import { Product } from '../models/product';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharingService } from '../Services/sharing-service';
import { RacemasterApiService } from '../Services/racemaster-api.service';
import { SingleResponse } from '../models/single-response';
import { StorageFile } from '../models/storage-file';
import { CollectionResponse } from '../models/collection-response';
import { Router } from '@angular/router';
import { Context } from '../models/context';

@Component({
  selector: 'app-products-component',
  templateUrl: './products-component.component.html',
  styleUrls: ['./products-component.component.css']
})
export class ProductsComponentComponent implements OnInit {
  productModel = new Product();
  productForm: FormGroup;
  productResponse: SingleResponse;
  imagesOnCloud = new Array<StorageFile>();
  filesOnCloud = new Array<StorageFile>();
  selectedImageName = "Select File";
  appContext = new Context();


  constructor(private fb: FormBuilder, private _sharingService: SharingService<Context>, private _service: RacemasterApiService, private _router: Router) {
    this.productForm = fb.group({
      id: ["", Validators.required],
      name: ["", Validators.required],
      shortDescription: ["", Validators.required],
      description: ["", Validators.required],
      downloadUrl: ["", Validators.required],
      imageUrl: ["", Validators.required]
    })
  }

  ngOnInit() {
    this._service.getAll<StorageFile>("FileStorage").subscribe(
      (data: CollectionResponse<StorageFile>) => {
        // storage file contains many types of files, so we filter collection returned so that it only contains imgs.
        this.imagesOnCloud = data.collection.filter(file => file.name.endsWith(".png") || file.name.endsWith(".jpg"));
        this.filesOnCloud = data.collection.filter(file => file.name.endsWith(".exe") || file.name.endsWith(".zip") || file.name.endsWith(".apk"))
      }
    )
  }
  onSelectFile(url: string) {
    this.productModel.downloadUrl = url;
  }
  onSelectImage(img: StorageFile) {
    this.productModel.imageUrl = img.downloadUrl;
    this.selectedImageName = img.name;
  }

  onSubmit() {
    this.productModel.id = this.productForm.controls["id"].value;
    this.productModel.name = this.productForm.controls["name"].value;
    this.productModel.shortDescription = this.productForm.controls["shortDescription"].value;
    this.productModel.description = this.productForm.controls["description"].value;


    this._service.post<Product>(this.productModel, "Products").subscribe(
      (data: SingleResponse) => {
        this.productResponse = data;
      },
      (err) => { alert(err.message); },
      () => {
        alert("Product added successfully!");
        this._router.navigate(["alladminpages"]);
      }
    );
  }
}
