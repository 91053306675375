import { Component, OnInit } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { SmartoddsSuboptionsComponent } from '../smartodds-suboptions/smartodds-suboptions.component';
import { SubPurchaseComponent } from '../sub-purchase/sub-purchase.component';
import { SharingService } from '../Services/sharing-service';
import { Context } from '../models/context';
import { SignInComponent } from '../sign-in/sign-in.component';
import { ProductSubscription } from '../models/product-subscription';
import { RacemasterSuboptionsComponent } from '../racemaster-suboptions/racemaster-suboptions.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smartodds-plus',
  templateUrl: './smartodds-plus.component.html',
  styleUrls: ['./smartodds-plus.component.css']
})
export class SmartoddsPlusComponent implements OnInit {
  isLoggedIn = false;
  appContext: Context;
  threeMonthsSub: ProductSubscription;
  twelveMonthsSub: ProductSubscription;
  sixMonthsSub: ProductSubscription;
  constructor(private modalService: ModalService, private _sharingService: SharingService<Context>,
    private _router: Router) {
      this.appContext = this._sharingService.getMessage();      
        this.isLoggedIn = this.appContext.activeUser != null;

  }

  ngOnInit() {
    this.threeMonthsSub = this.appContext.products.find(p => p.id === "FEDBC6A3-25EF-4D39-B012-D2B727108632").subscriptions.find(s => s.id === "37010C65-4B22-419F-AA67-0A09D0878E6F");
    this.sixMonthsSub = this.appContext.products.find(p => p.id === "FEDBC6A3-25EF-4D39-B012-D2B727108632").subscriptions.find(s => s.id === "F407C9A6-A10A-4E60-834B-9AD5754FA9C6");
    this.twelveMonthsSub = this.appContext.products.find(p => p.id === "FEDBC6A3-25EF-4D39-B012-D2B727108632").subscriptions.find(s => s.id === "124B91C9-8C60-4DFF-87B1-FA625B135127");
  }
  onSubmit(subId: string) {
    if (this.isLoggedIn) {
      this.subPurchaseModal(subId);
    }
    else {
      this._router.navigate(['signin']);
    }
  }



  home() {
    this._router.navigate(['home'])
  }

  subOptions() {
    this._router.navigate(['suboptions'])
  }


  subPurchaseModal(subId: string) {
    this.appContext.productId = "FEDBC6A3-25EF-4D39-B012-D2B727108632";
    this.appContext.subscriptionId = subId;
    this._sharingService.setMessage(this.appContext);
    this._router.navigate(['subpurchase']);
  }

}
